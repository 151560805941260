<template>
	<div>
		<div id="main">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive" />
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive" />
		</div>
	</div>
</template>
<script>
	import {
		getQueryString,
		funcUrlDel
	} from "@/util/util.js"
	export default {
		name: 'App',
		data() {

		},
		watch: {

		},
		methods: {
			initToken() {
				const token = getQueryString("token");
				this.$store.commit("set_token", token);
			}
		},
		computed: {

		},
		created() {
			this.initToken()
		}
	}
</script>
<style lang="less">
	#main {
		height: 100vh;
		width: 100vw;
		background-color: #f7f8fa;
		overflow: hidden;

	}

	#footer {
		height: 50px;
	}

	.safeBottom {
		height: env(safe-area-inset-bottom);
		height: constant(safe-area-inset-bottom);
	}

	.safeTop {
		height: env(safe-area-inset-top);
		height: constant(safe-area-inset-top);
	}
</style>