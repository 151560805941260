import {
	setToken,
	setRefreshToken,
	removeToken,
	removeRefreshToken
} from '@/util/auth.js'
import {
	Toast
} from 'vant';
import router from '@/router/router.js';
import {
	setStore,
	getStore
} from '@/util/store.js'
const oauth = {
	state: {
		token: getStore({
			name: 'token'
		}) || '',
	},
	actions: {

	},
	mutations: {
		set_token: (state, token) => {
			state.token = token;
			setStore({
				name: 'token',
				content: state.token
			})
		}
	}

}


export default oauth