import {
	createRouter,
	createWebHistory
} from 'vue-router'
import store from '@/store/';
import {
	Toast
} from 'vant';
// import wx from "weixin-sdk-js";
// const wx = require('@/util/jweixin.js');
const routes = [{
	path: '/game-center',
	name: 'game-center',
	meta: {
		title: "游戏大厅",
	},
	component: () => import('@/pages/game-center.vue')
}, {
	path: '/game-h5-start',
	name: 'game-h5-start',
	meta: {
		title: "玩游戏",
	},
	component: () => import('@/pages/game-h5-start.vue')
}]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	mode: 'history',
	routes
})




// router.beforeEach((to, from, next) => {
// 	if (to.meta.title) {
// 		document.title = to.meta.title
// 	}
// 	// Toast.loading({
// 	//   forbidClick: true,
// 	//   duration:0
// 	// });
// 	if (to.meta.isDiyShare) {
// 		next();
// 		return;
// 	}
// 	store.dispatch("getWechatJsConfig", window.location.href).then(res => {
// 		// const shareUrl = document.location.protocol + "//" + window.location.host + to.fullPath
// 		const shareUrl = document.location.protocol + "//" + window.location.host
// 		const shareImg = document.location.protocol + "//" + window.location.host + "/img/logo.png"
// 		const jsApiList = ["scanQRCode", "updateAppMessageShareData", "updateTimelineShareData",
// 			"checkJsApi", "chooseImage", "previewImage"
// 		]
// 		const wxConfig = Object.assign({
// 			// debug:process.env.NODE_ENV === "development",
// 			jsApiList: jsApiList,
// 			openTagList: ['wx-open-launch-weapp']
// 		}, store.state.views.wechatConfig)
// 		wx.config(wxConfig);
// 		wx.ready(() => {
// 			wx.updateAppMessageShareData({
// 				title: '西瓜公益传奇[WWW.38MIR.VIP]正版授权，20全满！', // 分享标题
// 				desc: '西瓜公益传奇、正版授权、百余版本、20全满、长期保证。', // 分享描述
// 				link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
// 				imgUrl: shareImg, // 分享图标
// 				success: function() {
// 					console.log("设置普通分享成功")
// 				}
// 			});
// 			wx.updateTimelineShareData({
// 				title: '二零服[WWW.38MIR.VIP]正版授权，百余版本20全满！', // 分享标题
// 				link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
// 				imgUrl: shareImg, // 分享图标
// 				success: function() {
// 					console.log("设置普通分享成功")
// 				}
// 			});
// 		})
// 		// Toast.clear()
// 		next()
// 	})
// })
// if(to.meta.tabbarName){
// 	store.commit("set_tabbarName",to.meta.tabbarName)
// }
// })

export default router